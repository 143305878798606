import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import MuiMenuItem from "@mui/material/MenuItem";

import variables from "../../../assets/css/scss/_variables.scss";

const selectHoverColor = variables.selectHover;
const selectActiveColor = variables.selectActive;
const whiteColor = variables.white;

const rootMenuCss = {
  backgroundColor: whiteColor,
  "&:focus": {
    outline: "none",
    backgroundColor: selectActiveColor,
  },
  "&:hover": {
    backgroundColor: selectHoverColor,
  },
};

const StyledMuiMenuItem = styled(MuiMenuItem)(() => ({
  [`&.MuiMenuItem-root`]: {
    ...rootMenuCss,
  },

  [`&.MenuItem-rootMenuWithEllipses`]: {
    ...rootMenuCss,
    ...menuItemEllipses,
  },

  [`&.MenuItem-selected.Mui-selected`]: {
    backgroundColor: selectActiveColor,
    "&:hover": {
      backgroundColor: selectActiveColor,
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },

  [`& .MenuItem-selectedMenu`]: {
    backgroundColor: selectActiveColor,
    "&:hover": {
      backgroundColor: selectActiveColor,
    },
    "&&": {
      backgroundColor: selectActiveColor,
      "&:hover": {
        backgroundColor: selectActiveColor,
      },
      "&:focus": {
        backgroundColor: selectActiveColor,
      },
    },
  },
}));

const menuItemEllipses = {
  "& .MuiListItemText-root .MuiListItemText-primary": {
    maxWidth: "150px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
};

const MenuItem = forwardRef((props, ref) => {
  const { classes, addEllipses = true } = props;
  return (
    <StyledMuiMenuItem
      classes={{
        root: addEllipses
          ? `MenuItem-rootMenuWithEllipses`
          : `MuiMenuItem-root`,
        selected: `MenuItem-selected`,
      }}
      divider={props.divider}
      key={props.optionValue}
      onClick={props.onClick}
      ref={ref}
      selected={props.isSelected}
      value={props.value}
    >
      {props.children}
    </StyledMuiMenuItem>
  );
});

MenuItem.propTypes = {
  addEllipses: PropTypes.bool,
  divider: PropTypes.bool,
  key: PropTypes.number,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};

MenuItem.displayName = "MenuItem";

export default MenuItem;
