import { connect } from "react-redux";

import AppBar from "./AppBarComponent";
import { toggleNavbar } from "../../actions/navBarActions";

const mapStateToProps = (state) => {
  return {
    isAnonymousUser: state.app.isAnonymousUser,
    isDrawerOpen: state.drawer.isOpen,
    isMyUser: state.app.isMyUser,
    showNavBar: state.navBar.showNavBar,
    windowSize: state.app.windowSize,
    isAuthenticated: state.app.isAuthenticated || state.app.isOneTimeUser,
    isInitialized: state.app.isInitialized,
    appBarComponent: state.appBar.appBarComponent,
    isUnauthState: state.app.isUnauthState,
    isPublicPage: state.app.isPublicPage,
    showLogoHideComponent: state.appBar.showLogoHideComponent,
    backButton: state.appBar.backButton,
    backButtonForDesktop: state.appBar.backButtonForDesktop,
    isHidden: state.navBar.isHidden,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleNavbar: () => {
      dispatch(toggleNavbar());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);
