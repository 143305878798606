import React from "react";
import PropTypes from "prop-types";

const sliceText = (input) => {
  const result = [];
  let temp = input;

  while (input.indexOf("[") >= 0) {
    temp = input.slice(0, input.indexOf("["));
    temp && result.push({ text: temp });
    input = input.substring(input.indexOf("["));
    temp = input.slice(input.indexOf("[") + 1, input.indexOf("]"));
    result.push({ key: `[${temp}]` });
    input = input.substring(input.indexOf("]") + 1);
  }

  input && result.push({ text: input });
  return result;
};

const getElement = (props, item) => {
  let link = <></>;

  // if key is found in text then replace with link
  props.replaceWithElement.forEach((keyVal) => {
    // Replace keys in resource files MUST be enclosed within square brackets
    const replaceKey = `[${keyVal.key}]`;
    if (item.key === replaceKey) link = keyVal.value;
  });

  return link;
};

const load = (props) => {
  const { fallback = "" } = props;
  let literalValue = fallback;
  try {
    const literalObj = props.literals[props.route][props.lKey];
    if (typeof literalObj === "string") {
      literalValue = literalObj;
    } else {
      // The literal has different values for application
      literalValue = literalObj[props.application];
    }
  } catch {
    // Ignore errors;
  }

  if (literalValue) {
    // Replace dynamic strings in the text
    if (props.replace) {
      props.replace.forEach((keyVal) => {
        // Replace keys in resource files MUST be enclosed within square brackets
        const replaceKey = `[${keyVal.key}]`;
        literalValue = literalValue.replace(replaceKey, keyVal.value);
      });
    }

    if (props.replaceWithElement) {
      literalValue = sliceText(literalValue).map((item, index) => {
        if (item.text) return item.text;
        return (
          <React.Fragment key={index}>{getElement(props, item)}</React.Fragment>
        );
      });
    }
  }

  return literalValue;
};

const Literal = (props) => {
  return <>{load(props)}</>;
};

Literal.propTypes = {
  fallback: PropTypes.string,
  lKey: PropTypes.string.isRequired,
  replace: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, val: PropTypes.string }),
  ),
  route: PropTypes.string.isRequired,
};

export default Literal;
