/* eslint no-unused-vars: ["error", { "varsIgnorePattern": "appStlye" }] */
import React from "react";

import { PRODOCTION_MODE } from "../../../constants/Text";

import appStlye from "../../../assets/css/scss/browserSupport.scss";

const ErrorComponent = (props) => {
  const showStack = process.env.NODE_ENV !== PRODOCTION_MODE;
  const renderStackDetails = (
    <div>
      <details
        style={{
          whiteSpace: "pre-wrap",
        }}
      >
        {props.error && props.error.toString()}
        <br /> {props.info.componentStack}
      </details>

      {props.level && props.level == "page" && (
        <button
          onClick={() => {
            window.location = "/Plus/Default.aspx";
          }}
        >
          {" "}
          Go Back to Dashboard
        </button>
      )}
    </div>
  );

  return (
    <div className="browsersupport dialog">
      <div className="backdrop" />
      <div className="dialog-modal">
        <div className="dialog-box">
          <div className="dialog-title">
            <h6>{props.title}</h6>
          </div>
          <div className="dialog-body">
            <p>
              {props.message}
              {showStack && renderStackDetails}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorComponent;
