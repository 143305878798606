import { connect } from "react-redux";

import { hideDrawer } from "../../actions/drawerActions";

import Drawer from "./DrawerComponent";

const mapStateToProps = (state) => {
  return {
    actions: state.drawer.actions,
    component: state.drawer.component,
    currentActivePage: state.root.rootAuth.currentActivePage,
    extraActions: state.drawer.extraActions,
    isAnonymousUser: state.app.isAnonymousUser,
    isAuthenticated: state.app.isAuthenticated,
    isMyUser: state.app.isMyUser,
    isPublicPage: state.app.isPublicPage,
    isOpen: state.drawer.isOpen,
    title: state.drawer.title,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // Drawer component
    hideDrawer: () => {
      dispatch(hideDrawer());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);
