import { connect } from "react-redux";

import LiteralProvider from "./LiteralProviderComponent";
import { loadLiterals } from "../../actions/literalActions";

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    lang: state.app.lang,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadLiterals: (lang) => {
      ownProps.routes.forEach((route) => {
        dispatch(loadLiterals(lang, route));
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LiteralProvider);
