import React from "react";
import styles from "./ComponentLoader.scss";

const ComponentLoader = (props) => {
  const classesNames = [];
  classesNames.push(styles["ut-comploader__outline"]);
  classesNames.push(props.className);

  if (props.isSmall) classesNames.push(styles["ut-comploader__outline--small"]);

  return (
    <div className={classesNames.join(" ")}>
      <div className={styles["ut-comploader__loader"]}>
        <svg className={styles["ut-comploader__svg"]} viewBox="25 25 50 50">
          <circle
            className={styles["ut-comploader__svg__path"]}
            cx="50"
            cy="50"
            r="20"
            fill="none"
          />
        </svg>
      </div>
    </div>
  );
};

export default ComponentLoader;
