import { connect } from "react-redux";

import Literal from "./LiteralComponent";

const mapStateToProps = (state, ownProps) => {
  return {
    ...ownProps,
    application: state.app.application,
    literals: state.literals.data,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Literal);
