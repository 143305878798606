import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import CustomScrollBar from "../CustomScrollBar";
import MenuDrawer from "../MenuDrawer/MenuDrawer";

import styles from "./DrawerComponent.scss";
import { trackUIEvent } from "../../../utils/tagManager";

const Drawer = (props) => {
  const [anchorEl, setanchorEl] = useState(null);
  const [showMenu, setShowMenu] = useState(false);

  const classNames = [];
  classNames.push(styles["ut-drawer"]);

  useEffect(() => {
    if (props.isOpen) document.documentElement.classList.add("drawer-open");
    else document.documentElement.classList.remove("drawer-open");
  }, [props.isOpen]);

  useEffect(() => {
    props.isOpen && props.hideDrawer();
  }, [props.currentActivePage]);

  if (props.isOpen) {
    classNames.push(styles["ut-drawer--open"]);
  }

  const handleOutlineCick = (event) => {
    if (
      event.target == document.getElementsByClassName(styles["ut-drawer"])[0]
    ) {
      props.hideDrawer();
    }
  };

  const renderActions = (actions) => {
    return actions.map((currentItem, index) => {
      const action = React.cloneElement(currentItem.component, {
        onClick: currentItem.callback && currentItem.callback(event),
        "data-tooltip": currentItem.tooltip,
      });
      return <span key={index}>{action}</span>;
    });
  };

  const handleDrawerClose = () => {
    trackUIEvent({
      category: "UI",
      action: "Click",
      value: "Drawer closed",
    });
    props.hideDrawer();
  };

  const onIconButtonClick = () =>{
    trackUIEvent({
      category: "UI",
      action: "Click",
      value: "More option icon"
    })
  }

  let headerClass = [styles["ut-drawer__header"]];
  if (
    props.isMyUser ||
    props.isAnonymousUser ||
    (!props.isAuthenticated && props.isPublicPage)
  ) {
    headerClass.push(styles["ut-drawer__header--public"]);
  }

  return (
    <div className={classNames.join(" ")} onClick={handleOutlineCick}>
      <div className={styles["ut-drawer__outline"]}>
        <div className={headerClass.join(" ")}>
          <IconButton
            className={styles["ut-drawer__header__close-btn"]}
            onClick={handleDrawerClose}
            size="large">
            <CloseIcon />
          </IconButton>

          <div className={styles["ut-drawer__header__cnt"]}>
            {props.title && <h4>{props.title}</h4>}

            <div className={styles["ut-drawer__header__cnt__apply-btn"]}>
              {props.actions.length > 0 && renderActions(props.actions)}
            </div>
            {props.extraActions.length > 0 && (
              <span className={styles["ut-drawer__header__menu"]}>
                <IconButton
                  onClick={(event) => {
                    setanchorEl(event.target);
                    setShowMenu(true);
                    onIconButtonClick();
                  }}
                  size="large">
                  <MoreVertIcon />
                </IconButton>
                <MenuDrawer
                  options={props.extraActions}
                  isOpen={showMenu}
                  handleClose={() => setShowMenu(false)}
                  anchorEl={anchorEl}
                />
              </span>
            )}
          </div>
        </div>

        <div className={styles["ut-drawer__cnt"]}>
          <CustomScrollBar>
            <div className={styles["ut-drawer__cnt__main"]}>
              {props.isOpen && props.component}
            </div>
          </CustomScrollBar>
        </div>
      </div>
    </div>
  );
};

Drawer.propTypes = {
  actions: PropTypes.array,
  extraActions: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Drawer;
