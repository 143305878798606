import React from "react";
import PropTypes from "prop-types";

import { Scrollbars } from "react-custom-scrollbars";
// t = current time
// b = start value
// c = change in value
// d = duration
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

class CustomScrollbars extends React.Component {
  constructor(props) {
    super(props);
  }

  scrollTop(to, duration) {
    duration = duration || 500;
    const { scrollbars } = this;
    const start = scrollbars.getScrollTop();
    const change = to - start;
    let currentTime = 0;
    const increment = 20;

    const animateScroll = function () {
      currentTime += increment;
      const val = Math.easeInOutQuad(currentTime, start, change, duration);
      scrollbars.scrollTop(val);
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  }

  // custom css for the scrollbar
  renderTrackVertical({ style, ...props }) {
    const thumbStyle = {
      width: "8px",
      right: "2px",
      bottom: "2px",
      top: "2px",
      borderRadius: "3px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  render() {
    const { autoHide = true } = this.props;
    return (
      <Scrollbars
        onScroll={this.props.onScroll}
        ref={(c) => {
          this.scrollbars = c;
        }}
        {...this.props}
        autoHide={autoHide}
        renderTrackVertical={this.renderTrackVertical}
        renderTrackHorizontal={(props) => (
          <div
            {...props}
            style={{ display: "none" }}
            className="track-horizontal"
          />
        )}
      />
    );
  }
}

CustomScrollbars.propTypes = {
  autoHide: PropTypes.bool,
};

export default CustomScrollbars;
