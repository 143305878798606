import { connect } from "react-redux";

import Logo from "./LogoComponent";

const mapStateToProps = (state) => {
  return {
    application: state.app.application,
    isMyUser: state.app.isMyUser,
    user: state.app.loggedInUser,
    isAuthenticated: state.app.isAuthenticated || state.app.isOneTimeUser,
    isAnonymousUser: state.app.isAnonymousUser,
    isPublicPage: state.app && state.app.isPublicPage,
    homePageUrl: state.app && state.app.homePageUrl
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Logo);
