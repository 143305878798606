import React from "react";

import { getLiteral, setDocumentTitle } from "../../../utils/commonService";
import { logToServer } from "../../../api/app";
import { LOG_LEVEL } from "../../../constants/JSON";

import ErrorComponent from "./ErrorComponent";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      info: null,
      error: null,
    };
    this.handleError = this.handleError.bind(this);
  }

  handleError(err) {
    let message = "";
    if (err instanceof Error) message = err.stack;
    if (err instanceof ErrorEvent) message = err.error.stack;
    const data = {
      LogLevel: LOG_LEVEL.Error,
      message,
    };
    logToServer(data);
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true, info, error });
    this.handleError(error);
    // You can also log the error to an error reporting service console.log(err
    // or);   logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorComponent
          documentTitle={setDocumentTitle(
            "ErrorComponentDocumentTitle",
            "constants",
          )}
          error={this.state.error}
          info={this.state.info}
          message={getLiteral("constants", "ErrorComponentMessage")}
          title={getLiteral("constants", "ErrorComponentTitle")}
          level={this.props.level}
        />
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
