import React from "react";
import PropTypes from "prop-types";
import _isEmpty from "lodash.isempty";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBack from "@mui/icons-material/ArrowBack";

import Logo from "../Logo/LogoContainer";
import LinearProgressBar from "../ProgressBar/LinearProgressBar/LinearProgressBarContainer";

import styles from "./AppBarComponent.scss";

const AppBar = (props) => {
  const classesNames = [];
  classesNames.push(styles["ut-header"]);

  //hiding navbar for iframe

  if (props.isHidden) classesNames.push(styles["ut-header--hide"]);

  // for my user blue header
  if (
    props.isMyUser ||
    props.isAnonymousUser ||
    (!props.isAuthenticated && props.isPublicPage)
  ) {
    classesNames.push(styles["ut-header--musafir-header"]);
  } else if (props.isAuthenticated && !props.isMyUser)
    classesNames.push(styles["ut-header--business-header"]);
  else if (!(props.isAuthenticated || props.isPublicPage))
    classesNames.push(styles["ut-header--unauth"]);

  if (!props.isInitialized) return null;

  let iconButton = null;
  if (props.showNavBar || !_isEmpty(props.backButton)) {
    const iconButtonClass = [];
    let iconOnClickAction = null;
    let iconButtonType = null;
    let buttonType = null;
    let formName = null;
    iconButtonClass.push(styles["ut-header__icon-btn"]);
    if (_isEmpty(props.backButton)) {
      iconOnClickAction = !props.isDrawerOpen
        ? props.toggleNavbar
        : props.hideDrawer;
      iconButtonClass.push(styles["ut-header__menu-btn"]);
      iconButtonType = <MenuIcon />;
    } else {
      iconOnClickAction = props.backButton.callback;
      iconButtonClass.push(styles["ut-header__back-btn"]);
      iconButtonType = <ArrowBack />;
      buttonType = props.backButton.buttonType;
      formName = props.backButton.formName;
    }

    if (props.backButtonForDesktop) {
      iconButtonClass.push(styles["ut-header__icon-btn__desktop"]);
    }

    iconButton = (
      <IconButton
        className={iconButtonClass.join(" ")}
        onClick={iconOnClickAction}
        type={buttonType}
        form={formName}
        size="large">
        {iconButtonType}
      </IconButton>
    );
  }
  return (
    <>
      <header className={classesNames.join(" ")}>
        <Logo
          hideOnTab={!props.isUnauthState}
          isNotTransparentBackground={!props.isUnauthState}
          showLogo={props.showLogoHideComponent}
        />
        {!props.showLogoHideComponent && (
          <>
            {iconButton}
            {props.appBarComponent}
          </>
        )}
      </header>
      <LinearProgressBar />
    </>
  );
};

AppBar.propTypes = {
  showNavBar: PropTypes.bool,
};

export default AppBar;
