import React, { Component } from "react";
import PropTypes from "prop-types";

class LiteralProvider extends Component {
  componentDidMount() {
    const { lang, loadLiterals } = this.props;
    loadLiterals(lang);
  }

  componentDidUpdate(prevProps) {
    const { lang: prevLang } = prevProps;
    const { lang, loadLiterals } = this.props;

    if (lang !== prevLang) {
      loadLiterals(lang);
    }
  }

  render() {
    return <>{this.props.children}</>;
  }
}

LiteralProvider.propTypes = {
  lang: PropTypes.string.isRequired,
  loadLiterals: PropTypes.func.isRequired,
};

export default LiteralProvider;
