import React from "react";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";

import { trackUIEvent } from "../../../utils/tagManager";
import MenuItem from "../MenuItem/MenuItem";
import Literal from "../Literal/LiteralContainer";

import styles from "./MenuDrawer.scss";

const getOptionLabel = (tooltip) => {
  if (tooltip && tooltip.route && tooltip.lKey)
    return (
      <Literal
        route={tooltip.route}
        lKey={tooltip.lKey}
        replace={tooltip.replace}
        replaceWithElement={tooltip.replaceWithElement}
      />
    );
  return tooltip;
};

const MenuDrawer = (props) => {
  const {
    addEllipses = true,
    anchorOrigin = {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin = {
      vertical: "top",
      horizontal: "center",
    },
  } = props;

  const handleClose = (event) => {
    event.persist();
    props.handleClose(event);
  };

  const handleEnter = () => {
    props.handleEnter && props.handleEnter();
  };

  const handleCallback = (event, option, shouldTrack) => {
    event.persist();
    props.handleClose(event);
    if (shouldTrack && !option.isSelected) {
      trackUIEvent({
        category: "UI",
        action: "Click",
        value: option.tooltip,
      });
    }
    option.callback && option.callback(event);
  };
  const handleIconClickCallback = (event, option) => {
    event.persist();
    props.handleClose(event);
    if (option.shouldTrack && !option.isSelected) {
      trackUIEvent({
        category: "UI",
        action: "Click",
        value: option.tooltip,
      });
    }
    option.iconCallback && option.iconCallback(event);
  };

  const { anchorEl } = props;

  const menuItems = props.hasMultipleMenuCategories
    ? null
    : props.options.map((option, index) => {
        return (
          <div key={index}>
            <MenuItem
              addEllipses={addEllipses}
              button={false}
              index={index}
              onClick={(event) => {
                event.persist();
                handleCallback(event, option, props.shouldTrack);
              }}
              isSelected={option.isSelected}
            >
              <ListItemText primary={getOptionLabel(option.tooltip)} />
              {option.icon && (
                <IconButton
                  className={styles["ut-menulist__item__btn"]}
                  onClick={(event) => {
                    event.persist();
                    event.preventDefault();
                    event.stopPropagation();
                    handleIconClickCallback(event, option);
                  }}
                  size="large"
                >
                  {option.icon}
                </IconButton>
              )}
            </MenuItem>
          </div>
        );
      });

  const multipleMenuItems = !props.hasMultipleMenuCategories
    ? null
    : props.options.map((optionArray, categoryIndex) => {
        return optionArray.map((option, index) => {
          return (
            <div key={index}>
              <MenuItem
                addEllipses={addEllipses}
                button={false}
                index={index}
                onClick={(event) => {
                  event.persist();
                  handleCallback(event, option);
                }}
                isSelected={option.isSelected}
                divider={
                  categoryIndex != props.options.length - 1 &&
                  index == optionArray.length - 1
                }
              >
                <ListItemText primary={getOptionLabel(option.tooltip)} />
                {option.icon && (
                  <IconButton
                    className={styles["ut-menulist__item__btn"]}
                    onClick={(event) => {
                      event.persist();
                      event.preventDefault();
                      event.stopPropagation();
                      handleIconClickCallback(event, option);
                    }}
                    size="large"
                  >
                    {option.icon}
                  </IconButton>
                )}
              </MenuItem>
            </div>
          );
        });
      });

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      id="simple-menu"
      onClose={handleClose}
      open={props.isOpen}
      transformOrigin={transformOrigin}
      TransitionProps={{
        onEnter: handleEnter,
      }}
    >
      {props.hasMultipleMenuCategories ? multipleMenuItems : menuItems}
    </Menu>
  );
};

MenuDrawer.propTypes = {
  addEllipses: PropTypes.bool,
  anchorEl: PropTypes.any,
  anchorOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(["bottom", "center", "top"]),
    horizontal: PropTypes.oneOf(["center", "left", "right"]),
  }),
  hasMultipleMenuCategories: PropTypes.bool,
  transformOrigin: PropTypes.shape({
    vertical: PropTypes.oneOf(["bottom", "center", "top"]),
    horizontal: PropTypes.oneOf(["center", "left", "right"]),
  }),
};

export default MenuDrawer;
